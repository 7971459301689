import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {
    DashboardStatsResponse,
    GameGlobalStatsResponse,
    GamePersonalStatsResponse,
    UserStatsResponse
} from "../../model/statsResponses";
import {DatedPeriod, periodToRequest} from "../../model/period";
import {GamePlayPeriodStatsResponse} from "../../model/responses";

@Injectable()
export class StatsService {

    constructor(
        private http: HttpClient
    ) {
    }

    getDashboardStats(): Observable<DashboardStatsResponse> {
        return this.http.get<DashboardStatsResponse>(`${environment.apiUrl}/stats/dashboard`);
    }

    getUserStats(userId: string, period: DatedPeriod): Observable<UserStatsResponse> {
        let params: any = {period: periodToRequest(period)}
        return this.http.get<UserStatsResponse>(`${environment.apiUrl}/stats/user/${userId}`, {params: params});
    }

    getGameGlobalStats(gameId: string): Observable<GameGlobalStatsResponse> {
        return this.http.get<GameGlobalStatsResponse>(`${environment.apiUrl}/stats/game/${gameId}`);
    }

    getGamePersonalStats(gameId: string): Observable<GamePersonalStatsResponse> {
        return this.http.get<GamePersonalStatsResponse>(`${environment.apiUrl}/stats/game/${gameId}/personal`);
    }
}
